<script setup lang="ts">
import { CmsBlockImageTextCover } from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsBlockImageTextCover;
}>();

const { getSlotContent } = useCmsBlock(props.content);

const leftContent = getSlotContent("left");
const rightContent = getSlotContent("right");

</script>
<template>
  <article class="cms-block-image-2-col md:grid md:grid-cols-2">
    <CmsGenericElement
      :content="leftContent"
      class="cms-block-image-text-cover"
      :class="`alias-${leftContent.data.apiAlias}`"
    />
    <CmsGenericElement
      :content="rightContent"
      class="cms-block-image-text-cover"
      :class="`alias-${rightContent.data.apiAlias}`"
    />
  </article>
</template>
